import * as React from "react"
import { graphql, useStaticQuery, Link } from "gatsby"
import { Nav } from "react-bootstrap"
import { faDiscord, faGithub } from "@fortawesome/free-brands-svg-icons"
import { faEnvelope } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Img from "gatsby-image"
import * as footerStyles from "./footer.module.scss"
import * as welcomeSectionStyles from "./home/welcome-section.module.scss"


const Footer = () => {
  const data = useStaticQuery(graphql`
      query {
          file(relativePath: {eq: "logo_vertical.png"}) {
              childImageSharp {
                  fixed(width: 115, quality: 100) {
                      ...GatsbyImageSharpFixed
                  }
              }
          }
      }
  `)
  return (
    <footer className="pd-lg-1">
      <div className='container-fluid'>
        <div className={`row ${footerStyles.footerNav}`}>
          <div className="col-md-12 col-lg-3 d-flex mb-5 justify-content-center align-items-center">
            <Link to="/">
              <Img
                className="gatsby-image"
                imgStyle={{ objectFit: 'contain' }}
                fixed={ data.file.childImageSharp.fixed }
              />
            </Link>
          </div>
          <div className="col-sm-4 col-lg-2">
            <Nav activeKey="/home" className={footerStyles.navBlock}>
              <h4>Piquasso</h4>
              <Nav.Item>
                <Nav.Link href="https://app.piquasso.com/">Simulator</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link as={Link} to='/team'>Our Team</Nav.Link>
              </Nav.Item>
            </Nav>
          </div>
          <div className="col-sm-4 col-lg-2">
            <Nav activeKey="/home" className={footerStyles.navBlock}>
              <h4>Help</h4>
              <Nav.Item>
                <Nav.Link href="https://docs.piquasso.com/tutorials/getting-started.html">Getting Started</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link href="https://docs.piquasso.com/">Documentation</Nav.Link>
              </Nav.Item>
            </Nav>
          </div>
          <div className="col-sm-4 col-lg-2">
            <Nav activeKey="/home" className={footerStyles.navBlock}>
              <h4>Further Information</h4>
              <Nav.Item>
                <Nav.Link href="https://docs.piquasso.com/tutorials/getting-started.html">FAQ</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link as={Link} to="/privacy">Terms & Conditions</Nav.Link>
              </Nav.Item>
            </Nav>
          </div>
          <div className="col-sm-12 col-lg-3">
            <Nav className={`${footerStyles.navBlock} ${footerStyles.followUs}`}>
              <h4>Contact Us</h4>
              <div className="d-flex flex-row align-items-center">
                <FontAwesomeIcon className={footerStyles.footerEnvelop} icon={ faEnvelope } size="2x" />
                piquassoquantum@gmail.com
              </div>
              <div className={`d-flex flex-row mt-3 ${footerStyles.contactSocial}`}>
                <a href="https://github.com/Budapest-Quantum-Computing-Group" className="mr-3">
                  <FontAwesomeIcon className={welcomeSectionStyles.githubBtn} icon={ faGithub } size="2x" />
                </a>
                <a href="https://discord.com/channels/842331946029416448/842331946029416453">
                  <FontAwesomeIcon className={welcomeSectionStyles.discordBtn} icon={ faDiscord } size="2x" />
                </a>
              </div>
            </Nav>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
