import * as React from "react"
import { graphql, Link, useStaticQuery } from "gatsby"
import { Nav, Navbar } from "react-bootstrap"
import * as headerStyles from "./header.module.scss"
import Img from "gatsby-image"

const Header = () => {
  const data = useStaticQuery(graphql`
      query {
          file(relativePath: {eq: "logo.png"}) {
              childImageSharp {
                  fluid(maxWidth: 200, quality: 50) {
                      ...GatsbyImageSharpFluid
                      ...GatsbyImageSharpFluidLimitPresentationSize
                  }
              }
          }
      }
  `)
  return (
    <header className={headerStyles.header}>
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <Navbar expand="lg">
              <Navbar.Brand as={Link} to='/' style={{width: '12rem'}}>
                <Img
                  className="gatsby-image"
                  fluid={ data.file.childImageSharp.fluid }
                />
              </Navbar.Brand>
              <Navbar.Toggle aria-controls="basic-navbar-nav" />
              <Navbar.Collapse id="basic-navbar-nav">
                <Nav className={`ml-auto ${headerStyles.nav}`}>
                  <Nav.Link as={Link} to='/'>Home</Nav.Link>
                  <Nav.Link href="https://app.piquasso.com/">Simulator</Nav.Link>
                  <Nav.Link href="https://docs.piquasso.com/">Documentation</Nav.Link>
                  <Nav.Link as={Link} to="/reference/">References</Nav.Link>
                  <Nav.Link as={Link} to="/team/">Our Team</Nav.Link>
                </Nav>
              </Navbar.Collapse>
            </Navbar>
          </div>
        </div>
      </div>
    </header>
  )
}
export default Header
