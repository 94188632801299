import * as React from "react"
import { Link } from "gatsby"
import * as cookieConsentStyles from "./cookie.module.scss"
import CookieConsent from "react-cookie-consent"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCookieBite } from "@fortawesome/free-solid-svg-icons"

const Cookie = () => {
  return (
    <div className={`${cookieConsentStyles.cookieLayoutWrapper} ${cookieConsentStyles.cookieConsentFloatIn}`}>
      <div className="container">
        <CookieConsent
          enableDeclineButton
          flipButtons
          containerClasses={`col-lg-12 ${cookieConsentStyles.cookieConsentContainer}`}
          location="bottom"
          buttonText="I understand"
          declineButtonText="Decline"
          cookieName="gatsby-gdpr-google-analytics"
          disableStyles={true}
          buttonClasses={cookieConsentStyles.cookieConsentButton}
          declineButtonClasses={cookieConsentStyles.cookieDeclineButton}
        >
          <div className={cookieConsentStyles.cookieConsentTextContainer}>
            <FontAwesomeIcon className={cookieConsentStyles.cookieIcon} icon={faCookieBite} size="2x"/>
            <div className="d-flex flex-column">
              <div>We use third-party cookies in order to personalize your site experience.</div>
              <div>
                <Link className={cookieConsentStyles.learnMore} to="privacy">
                  Learn more&nbsp;
                </Link>
                for a complete overview of all cookies used.
              </div>
            </div>
          </div>
        </CookieConsent>
      </div>
    </div>
  )
}

export default Cookie
