// extracted by mini-css-extract-plugin
export var wrapper = "welcome-section-module--wrapper--qyQw3";
export var actionButtonLayout = "welcome-section-module--actionButtonLayout--acJYD";
export var actionButtons = "welcome-section-module--actionButtons--RJOHJ";
export var actionButtonSimulator = "welcome-section-module--actionButtonSimulator--e2Q1W";
export var actionButtonDocumentation = "welcome-section-module--actionButtonDocumentation--mfFF1";
export var findUsWrapper = "welcome-section-module--find-us-wrapper--+srdc";
export var githubBtn = "welcome-section-module--github-btn--6YfId";
export var discordBtn = "welcome-section-module--discord-btn--6nCqZ";
export var envelopIcon = "welcome-section-module--envelop-icon--9JaRf";
export var videoPlayer = "welcome-section-module--video-player--H6oGA";